import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider } from 'jotai/react'
import { useHydrateAtoms } from 'jotai/react/utils'
import { queryClientAtom } from 'jotai-tanstack-query'
import { Suspense } from 'react'
import { I18nProvider } from 'react-aria-components'

import { TwSpinner } from '@components/TwSpinner/TwSpinner.tsx'
import { getFullLocale } from '@utils/locale.ts'

import { queryClient } from './query-client.ts'

import type { ReactNode } from 'react'

const HydrateAtoms = ({ children }: { children: ReactNode }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]])
  return children
}

export function GlobalQueryClientProvider({
  children,
}: {
  readonly children: ReactNode
}) {
  return (
    <I18nProvider locale={getFullLocale()}>
      <QueryClientProvider client={queryClient}>
        <Provider>
          <HydrateAtoms>
            <Suspense fallback={<TwSpinner />}>
              {children}
              <ReactQueryDevtools initialIsOpen={false} />
            </Suspense>
          </HydrateAtoms>
        </Provider>
      </QueryClientProvider>
    </I18nProvider>
  )
}
