import * as Sentry from '@sentry/browser'
import { MutationCache, QueryClient } from '@tanstack/react-query'

import { isZodError } from '@utils/zod.ts'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false, // default: true
    },
  },
  mutationCache: new MutationCache({
    onError(error, context) {
      if (isZodError(error)) {
        console.error('zod error', error.format())
        Sentry.captureException(error, (scope) => {
          scope.setExtra('query context', context)
          scope.setExtras(error.format())
          return scope
        })
      } else {
        Sentry.captureException(error)
      }
    },
  }),
})
