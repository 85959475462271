export function getFullLocale() {
  switch (Translator.locale) {
    case 'en': {
      return 'en-GB'
    }
    default: {
      return Translator.locale
    }
  }
}
